/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";

import { Editor } from "@tinymce/tinymce-react";
import clsx from "clsx";

import { graphql, useStaticQuery } from "gatsby";
import InputWrapper from "../../inputWrapper";
import LoadingIcon from "../../../loadingIcon";
import TextareaField from "../../fields/textareaField";
import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../../context/SubmissionContext";

import { DictionaryDataState } from "../../../../context/DictionaryDataContext";

import InputField from "../../fields/inputField";
import SelectField from "../../fields/selectField";

import DemoData from "../../../../helpers/pdf/demoData";
import MyDocument from "../../../../helpers/pdf/pdfDocument";
import dictionaryDataHelpers from "../../../../helpers/dictionaryDataHelpers";

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: Object;
  submissionStatus?: any;
  missingRequiredFields?: any;
  materialID?: string;
}

export default function OasisResearchDetailsStep(props: StepProps) {
  const dictionaryDataState: any = useContext(DictionaryDataState) || [];

  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { triggerSaveMessageHandler, materialSettings, materialID } = props;

  // TinyMCE Editor Refs
  const pdfWhatResearchersDidTextEditorRef = useRef(null);
  const pdfWhatResearchersFoundTextEditorRef = useRef(null);
  const pdfThingsToConsiderTextEditorRef = useRef(null);

  // States
  const [loaded, setLoaded] = useState(true);
  const [activeTab, setActiveTab] = useState("tab1");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [warning, setWarning] = useState("");
  const [pdfName, setPdfName] = useState("");

  const enterDemoData = (characterSet: string) => {
    const demoData = DemoData[characterSet];

    if (!demoData) {
      console.error(`Character set "${characterSet}" not found in DemoData.`);
      return null;
    }

    const submissionDispatchParams = {
      type: "insertMaterialMetadata",
      value: {
        research: {
          researchTitle: demoData.researchTitle,
          researchText: demoData.researchText,
          whatResearchersDidText: demoData.whatResearchersDidText,
          whatResearchersFoundText: demoData.whatResearchersFoundText,
          thingsToConsiderText: demoData.thingsToConsiderText,
        },
      },
    };

    submissionDispatch(submissionDispatchParams);
  };

  useEffect(() => {
    if (totalPages > 1) {
      setWarning(
        "Attention: The OASIS summary can only be 1 page long. Please revise the text entered to make sure the OASIS summary is only 1 page long."
      );
    } else {
      setWarning("");
    }
  }, [totalPages]);

  /**
   * In order to make this work during the build we need to use this fix:
   * https://stackoverflow.com/questions/76007339/server-error-error-pdfdownloadlink-is-a-web-specific-api-youre-either-using-t
   */
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    // OASIS_Summary_[Original publication authors]_[Year]

    let pdfNameStr = "OASIS_Summary";

    if (submissionState) {
      try {
        let publicationAuthorNames = "";
        if (
          submissionState.publication[0].publicationAuthor &&
          submissionState.publication[0].publicationAuthor.length > 0
        ) {
          const selectedValues = dictionaryDataHelpers.getSelectedValues(
            submissionState.publication[0].publicationAuthor
          );
          const publicationAuthors = dictionaryDataHelpers.lookupDictionaryData(
            dictionaryDataState,
            "oasisAuthors",
            selectedValues
          );
          publicationAuthorNames = publicationAuthors
            .map((item: any) => item.label)
            .join("_")
            .replaceAll(/\W/gi, "_");

          pdfNameStr += `_${publicationAuthorNames}`;

          pdfNameStr += `_${submissionState.publication[0].publicationDate.label}`;
        }
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error);
      }
    }

    setPdfName(pdfNameStr);
  }, [submissionState]);

  // Classes
  const tabClasses = "tab font-normal";
  const tabButtonClasses =
    "inline-block p-2 text-blue-600 bg-whitexx rounded-t-lg mr-2 bg-oasis-blue hover:bg-oasis-blue-hover text-white border-0";
  const numberOfPagesInfoClasses = "inline-block";

  // const tabClasses =
  // "inline-block p-2 ?text-blue-600 bg-whitexx rounded-t-lg mr-2 tab font-normal";

  // const tabButtonClasses =
  //   "inline-block p-2 text-blue-600 bg-white rounded-t-lg mr-2";
  // const pdfClasses = "tab font-normal";

  // Tabs
  const tabs = [
    { id: "1", label: "Summary Details", key: "tab1" },
    { id: "2", label: "PDF Preview", key: "tab2" },
  ];

  const {
    introText,
    bottomText,
    infoBoxesContent,
    researchAboutText,
    researchersDidText,
    researchersFoundText,
    thingsToConsiderText,
    oasisFooterText,
  } = useStaticQuery(graphql`
    query {
      introText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-3" } }
      ) {
        html
      }

      bottomText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-3-bottom" } }
      ) {
        html
      }

      researchAboutText: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-submission-step-3-research-about" }
        }
      ) {
        frontmatter {
          title
        }
        html
      }

      researchersDidText: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-submission-step-3-researchers-did" }
        }
      ) {
        frontmatter {
          title
        }
        html
      }

      researchersFoundText: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-submission-step-3-researchers-found" }
        }
      ) {
        frontmatter {
          title
        }
        html
      }

      thingsToConsiderText: markdownRemark(
        frontmatter: {
          pageId: { eq: "OASIS-submission-step-3-things-to-consider" }
        }
      ) {
        frontmatter {
          title
        }
        html
      }

      infoBoxesContent: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-info-boxes" } }
      ) {
        frontmatter {
          theTitleOfYourOnePageSummary
          whatThisResearchWasAboutAndWhyItIsImportant
          whatTheResearchersDid
          whatTheResearchersFound
          thingsToConsider
        }
      }

      oasisFooterText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-pdf-footer" } }
      ) {
        frontmatter {
          footer_line_1
          footer_line_2
        }
      }
    }
  `);

  // const tabsClasses = "";
  // "primaryBackgroundColor hover:primaryBackgroundColor-hover text-white no-underline py-2 px-4 rounded text-center shadow-md mt-5";

  return loaded ? (
    <>
      {introText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: introText.html,
            }}
          />
        </div>
      ) : null}

      <div style={{ marginBottom: `20px` }}>
        <div className="flex flex-wrap font-medium text-center border-b border-gray relative">
          {tabs.map(({ id, label, key }) => (
            <button
              key={id}
              id={id}
              type="button"
              aria-label={`Open tab ${label}`}
              onClick={() => setActiveTab(key)}
              className={clsx(
                tabClasses,
                tabButtonClasses,
                activeTab === key && "active"
              )}
            >
              {label}
            </button>
          ))}

          <div className="absolute right-0">
            {process.env.GATSBY_ENV !== "production" ? (
              <>
                <button
                  type="button"
                  className={clsx(tabButtonClasses)}
                  onClick={() => enterDemoData("latin")}
                >
                  latin
                </button>

                <button
                  type="button"
                  className={clsx(tabButtonClasses)}
                  onClick={() => enterDemoData("script")}
                >
                  scripts
                </button>

                <div className={clsx(tabClasses, numberOfPagesInfoClasses)}>
                  Pages: {totalPages}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className={clsx(tabClasses, activeTab === "tab1" ? "activeTab" : "")}
      >
        <InputWrapper
          title="The title of your one page summary"
          infoBoxContent={
            infoBoxesContent.frontmatter.theTitleOfYourOnePageSummary
          }
          required
        >
          <InputField
            name="researchTitle"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            doiField
          />
        </InputWrapper>

        <InputWrapper
          title={researchAboutText ? researchAboutText?.frontmatter?.title : ""}
          infoBoxContent={
            infoBoxesContent.frontmatter
              .whatThisResearchWasAboutAndWhyItIsImportant
          }
          required
        >
          {researchAboutText.html ? (
            <div className="mb-3">
              <div
                className="markdownText p0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: researchAboutText.html,
                }}
              />
            </div>
          ) : null}

          <TextareaField
            name="researchText"
            height={200}
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            additionalClasses="rounded border border-gray-2 px-2"
          />
        </InputWrapper>

        <InputWrapper
          title={
            researchersDidText ? researchersDidText?.frontmatter?.title : ""
          }
          infoBoxContent={infoBoxesContent.frontmatter.whatTheResearchersDid}
          required
        >
          {researchersDidText.html ? (
            <div className="mb-3">
              <div
                className="markdownText p0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: researchersDidText.html,
                }}
              />
            </div>
          ) : null}

          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey={process.env.GATSBY_TinyMCE_licenseKey}
            onInit={(_evt, editor) =>
              (pdfWhatResearchersDidTextEditorRef.current = editor)
            }
            onEditorChange={(newValue, editor) => {
              submissionDispatch({
                type: "researchChangeHandler",
                value: newValue,
                fieldName: "whatResearchersDidText",
              });

              triggerSaveMessageHandler();
            }}
            value={submissionState?.research?.whatResearchersDidText}
            init={{
              height: 300,
              menubar: false,
              plugins: ["lists", "preview", "paste"],
              toolbar: "bold italic bullist removeformat pastetext",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              // Optionally, force all pasted content to be plain text
              paste_as_text: true,
            }}
          />
        </InputWrapper>

        <InputWrapper
          title={
            researchersFoundText ? researchersFoundText?.frontmatter?.title : ""
          }
          infoBoxContent={infoBoxesContent.frontmatter.whatTheResearchersFound}
          required
        >
          {researchersFoundText.html ? (
            <div className="mb-3">
              <div
                className="markdownText p0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: researchersFoundText.html,
                }}
              />
            </div>
          ) : null}

          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey={process.env.GATSBY_TinyMCE_licenseKey}
            onInit={(_evt, editor) =>
              (pdfWhatResearchersFoundTextEditorRef.current = editor)
            }
            onEditorChange={(newValue, editor) => {
              submissionDispatch({
                type: "researchChangeHandler",
                value: newValue,
                fieldName: "whatResearchersFoundText",
              });

              triggerSaveMessageHandler();
            }}
            value={submissionState?.research?.whatResearchersFoundText}
            init={{
              height: 300,
              menubar: false,
              plugins: ["lists", "preview", "paste"],
              toolbar: "bold italic bullist removeformat pastetext",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              // Optionally, force all pasted content to be plain text
              paste_as_text: true,
            }}
          />
        </InputWrapper>

        <InputWrapper
          title={
            thingsToConsiderText ? thingsToConsiderText?.frontmatter?.title : ""
          }
          infoBoxContent={infoBoxesContent.frontmatter.thingsToConsider}
          required
        >
          {thingsToConsiderText.html ? (
            <div className="mb-3">
              <div
                className="markdownText p0"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: thingsToConsiderText.html,
                }}
              />
            </div>
          ) : null}

          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey={process.env.GATSBY_TinyMCE_licenseKey}
            onInit={(_evt, editor) =>
              (pdfThingsToConsiderTextEditorRef.current = editor)
            }
            onEditorChange={(newValue, editor) => {
              submissionDispatch({
                type: "researchChangeHandler",
                value: newValue,
                fieldName: "thingsToConsiderText",
              });

              triggerSaveMessageHandler();
            }}
            value={submissionState?.research?.thingsToConsiderText}
            init={{
              height: 300,
              menubar: false,
              plugins: ["lists", "preview", "paste"],
              toolbar: "bold italic bullist removeformat pastetext",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              // Optionally, force all pasted content to be plain text
              paste_as_text: true,
            }}
          />
        </InputWrapper>

        <div className="hidden">
          This must be hidden. Do not remove this otherwise the classes are not
          loaded.
          <SelectField name="nothing" options={[]} />
        </div>

        {warning ? (
          <div
            style={{
              display: `block`,
              backgroundColor: `#f2a600`,
              padding: `10px`,
              color: `white`,
            }}
          >
            {warning}
          </div>
        ) : null}

        <div className="mb-5 border-b border-b-gray-2">
          {bottomText.html ? (
            <div className="pb-5">
              <div
                className="markdownText p0xx"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: bottomText.html,
                }}
              />
            </div>
          ) : null}

          <button
            key={123123}
            id="123123"
            type="button"
            aria-label="Open tab PDF Preview"
            onClick={() => setActiveTab(`tab2`)}
            className={clsx(
              tabClasses,
              tabButtonClasses,
              activeTab === `tab2` && "active",
              `mb-5 rounded-lg`
            )}
          >
            PDF Preview
          </button>
        </div>
      </div>

      <div
        className={clsx(tabClasses, activeTab === "tab2" ? "activeTab" : "")}
      >
        {isClient ? (
          <PDFViewer
            width="100%"
            height="1000px"
            showToolbar
            // fileName={pdfName}
          >
            <MyDocument
              materialMetadata={submissionState}
              objectMetadata={submissionState.objectMetadata}
              setTotalPages={setTotalPages}
              dictionaryDataState={dictionaryDataState}
              materialId={materialID}
              materialTitle={pdfName}
              footerText={oasisFooterText}
            />
          </PDFViewer>
        ) : null}
      </div>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import H1 from "../components/htmlElements/h1";
import Layout from "../components/layout";
import { getProjectName } from "../lib/functions";

export default function HelpPage() {
  const projectName = getProjectName(true);

  const { irisText, oasisText } = useStaticQuery(graphql`
    query {
      irisText: markdownRemark(frontmatter: { pageId: { eq: "IRIS-help" } }) {
        frontmatter {
          title
        }
        html
      }

      oasisText: markdownRemark(frontmatter: { pageId: { eq: "OASIS-help" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  let homepageContent = "";

  if (irisText && projectName === "IRIS" && irisText.html) {
    homepageContent = irisText.html;
  }

  if (oasisText && projectName === "OASIS" && oasisText.html) {
    homepageContent = oasisText.html;
  }

  homepageContent = homepageContent
    .replaceAll(
      "<h2>",
      `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`
    )
    .replaceAll(
      "<h3>",
      `<h3 class="text-xl font-bold font-heading pb-5 pt-5">`
    );

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1
            innerContent={
              projectName === "OASIS"
                ? oasisText.frontmatter.title
                : irisText.frontmatter.title
            }
            additionalClasses="pb-5"
          />

          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: homepageContent,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

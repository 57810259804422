import React from "react";
import { v4 as uuidv4 } from "uuid";
import { graphql, useStaticQuery, Link } from "gatsby";

interface StepProps {
  submissionStatus: any;
  missingRequiredFields: any;
  materialID: string;
}

export default function OasisSubmissionStep(props: StepProps) {
  const { missingRequiredFields, submissionStatus, materialID } = props;

  const steps: any = {
    summary: 1,
    publication: 2,
    research: 3,
    upload: 4,
    participant: 5,
    settings: 6,
  };

  const missingFields = missingRequiredFields.map((item: any) => {
    if (item.message) {
      return <li key={uuidv4()}>{item.message}</li>;
    }
    const inStep = steps[item.partOf];

    return (
      <li key={uuidv4()}>
        Please enter a `{item.displayName}` (
        <Link to={`/submit/${materialID}/${inStep}`}>see step {inStep}</Link>)
      </li>
    );
  });

  const { submittonText } = useStaticQuery(graphql`
    query {
      submittonText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-7" } }
      ) {
        html
        frontmatter {
          submittingText
          submittedText
          errorText
          missingFieldsText
          summaryOkayText
        }
      }
    }
  `);

  return (
    <>
      {submittonText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: submittonText.html,
            }}
          />
        </div>
      ) : null}

      {missingFields.length === 0 && submissionStatus === "" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.summaryOkayText}
        </div>
      ) : null}

      {submissionStatus === "submitting" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.submittingText}
        </div>
      ) : null}

      {submissionStatus === "submitted" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.submittedText}
        </div>
      ) : null}

      {submissionStatus === "error" ? (
        <div className="text-lg pb-5">
          {submittonText.frontmatter.errorText}
        </div>
      ) : null}

      {missingFields.length > 0 ? (
        <div style={{ marginBottom: `15px` }}>
          {submittonText.frontmatter.missingFieldsText}
          <br />
          <ul style={{ listStyle: `inside` }}>{missingFields}</ul>
        </div>
      ) : null}
    </>
  );
}

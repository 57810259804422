import React, { useEffect } from "react";
import { PageProps, navigate } from "gatsby";
import Layout from "../../../components/layout";

export default function OasisLegacyRedirect(props: PageProps) {
  const { id } = props;

  useEffect(() => {
    (async () => {
      navigate(`/details/${id}`);
    })();
  });

  return (
    <Layout>
      <div className="grid grid-cols-1 text-center">Loading, please wait.</div>
    </Layout>
  );
}

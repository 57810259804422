/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from "react";
import { Link, PageProps } from "gatsby";
import clsx from "clsx";
import {
  validateToken,
  userRightsDecoded,
  getProjectName,
} from "../../../lib/functions";
import LoadingIcon from "../../../components/loadingIcon";
import Layout from "../../../components/layout";
import LeftColumnContent from "../../../components/submitElements/leftColumnContent";
import RightColumnContent from "../../../components/submitElements/rightColumnContent";

import { AuthenticationState } from "../../../context/AuthenticationContext";
import { SubmissionState, SubmissionDispatch } from "../../../context/SubmissionContext"; // eslint-disable-line prettier/prettier
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import { submitMaterialMetadata } from "../../../components/submitElements/submitMaterialMetadata";
import metadataReverseFormatter from "../../../helpers/iris/MetadataReverseFormatter";
import getDetailsData from "../../../helpers/getDetailsMetadata";
import oasisMetadataReverseFormatter from "../../../helpers/oasis/OasisReverseMetadataFormatter";
import StepDisplay from "../../../components/submitElements/stepDisplay";
import submissionFunctions from "../../../lib/submissionFunctions";
import validateSubmissionForm from "../../../helpers/validateSubmittionForm";

interface SubmitPageProps extends PageProps {
  materialID: string;
  step: string;
}

export default function SubmitPage(props: SubmitPageProps) {
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const projectName = getProjectName(true);

  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const dictionaryDataState = useContext(DictionaryDataState) || "";

  const { token, isAuthenticated } = authenticationState;
  const { materialID, step } = props;

  const stepInt = parseInt(step, 10);

  const [triggerSaveMessage, setTriggerSaveMessage] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [missingRequiredFields, setMissingRequiredFields] = useState([]);
  const [message, setMessage] = useState("");
  const [materialSettings, setMaterialSettings] = useState({});
  const [materialMetadataLoaded, setMaterialMetadataLoaded] = useState(false);
  const [filesLoaded, setFilesLoaded] = useState(false);

  const [isSubmissionStep, setIsSubmissionStep] = useState(false);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);
  const [updateDoiReference, setUpdateDoiReference] = useState(false);

  // For token error handling
  const [stepOverlayClasses, setStepOverlayClasses] =
    useState<string>("hidden");
  const [stepOverlayMessage, setStepOverlayMessage] = useState<any>("");

  /**
   * Actions to take when the `step` changes
   */
  useEffect(() => {
    (async () => {
      /**
       * Check user token each step to make sure he has a valid token
       */
      if (token && isAuthenticated) {
        await validateToken(token);
      }

      /**
       * Update isSubmissionStep & isConfirmationStep for each step
       */
      setIsSubmissionStep(submissionFunctions.isSubmissionStep(stepInt));
      setIsConfirmationStep(submissionFunctions.isConfirmationStep(stepInt));
      setUpdateDoiReference(
        submissionFunctions.isUpdateDoiReferenceStep(stepInt)
      );
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  /**
   * This hook validates the submission form when the `isSubmissionStep` is true.
   * - For the "IRIS" project, it uses the `validateSubmissionForm` function to check all steps of the submission form.
   * - For the "OASIS" project, it uses the `checkRequiredFields` function from `submissionFunctions`.
   * - Based on the validation results, it updates the `missingRequiredFields` state.
   *
   * Dependencies:
   * - `isSubmissionStep`: Triggers the validation when it changes.
   * - `submissionState`: Provides the current state of the submission form for validation.
   */
  useEffect(() => {
    (async () => {
      if (isSubmissionStep) {
        let invalidFields: any = [];

        if (projectName === "IRIS") {
          invalidFields = await validateSubmissionForm({
            submissionState,
            allSteps: true,
          });
        }

        console.log("invalidFields: ", invalidFields);

        if (projectName === "OASIS") {
          invalidFields =
            submissionFunctions.checkRequiredFields(submissionState);
        }

        if (invalidFields.length > 0) {
          setMissingRequiredFields(invalidFields);
        } else {
          setMissingRequiredFields([]);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmissionStep, submissionState]);

  /**
   * When switching from step 4 to step 5, we need to load in the details again to get the DOI Reference.
   * Store the DOI information in the submissionState so we can display the information on step 5.
   */
  useEffect(() => {
    (async () => {
      if (materialID && Object.keys(dictionaryDataState).length) {
        // reload details
        const details = await getDetailsData({
          materialID,
          isAuthenticated,
          token,
        });

        submissionDispatch({
          type: "INSERT_METADATA",
          doi: details.doi,
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDoiReference]);

  /**
   * Check user token each step to make sure he has a valid token
   */
  useEffect(() => {
    (async () => {
      if (token && isAuthenticated) {
        await validateToken(token);
      }
    })();
  }, [step]);

  /**
   * Load in all material metadata
   *
   * ONLY WHEN materialID is available
   */
  useEffect(() => {
    (async () => {
      if (materialID && Object.keys(dictionaryDataState).length) {
        //

        const details = await getDetailsData({
          materialID,
          isAuthenticated,
          token,
        });

        const userRightsAndSettings = userRightsDecoded(
          materialID,
          details.userRights
        );

        if (
          userRightsAndSettings.isOwner ||
          userRightsAndSettings.isAdminUser
        ) {
          setMaterialSettings(userRightsAndSettings);

          try {
            //
            // Here we are running the material metadata through the reverse formatter
            // before saving it to the submission state

            let newMetadata = {};

            if (projectName === "IRIS") {
              newMetadata = details.materialMetadata
                ? metadataReverseFormatter({
                    submissionState,
                    dictionaryData: dictionaryDataState,
                    metadata: { data: details.materialMetadata },
                  })
                : {};
            }

            if (projectName === "OASIS") {
              newMetadata = oasisMetadataReverseFormatter({
                // submissionState,
                dictionaryData: dictionaryDataState,
                metadata: { data: details.materialMetadata },
              });
            }

            const insertMetadataParams = {
              type: "INSERT_METADATA",
              objectId: materialID,
              objectMetadata: details.objectMetadata,
              materialMetadata: newMetadata,
              filesMetadata: details.filesMetadata,
              doi: details.doi,
            };

            submissionDispatch(insertMetadataParams);

            setMaterialMetadataLoaded(true);
            setFilesLoaded(true);

            //
          } catch (error) {
            console.log("🚀 ~ error", error);
            setMessage(
              `Sorry, an error occurred. Please contact the admin team. (error code "step${step}#2")`
            );
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialID, dictionaryDataState]);

  /**
   * When switching from step 4 to step 5, we need to load in the details again to get the DOI Reference.
   * Store the DOI information in the submissionState so we can display the information on step 5.
   */
  useEffect(() => {
    (async () => {
      if (parseInt(step) === 5) {
        if (materialID && Object.keys(dictionaryDataState).length) {
          // reload details
          const details = await getDetailsData({
            materialID,
            isAuthenticated,
            token,
          });

          submissionDispatch({
            type: "INSERT_METADATA",
            doi: details.doi,
          });
        }
      }
    })();
  }, [step]);

  const triggerSaveMessageHandler = async () => {
    // trigger save message
    // setTriggerSaveMessage(true);

    // Submit material
    const response: any = await submitMaterialMetadata({
      submissionState,
      dictionaryDataState,
      token: authenticationState.token,
    });

    if (response?.status !== 200) {
      // The token might be expired so the user has to login again otherwise we can't save the data
      setStepOverlayClasses(""); // removes the `hidden` class
      setStepOverlayMessage(
        <>
          Sorry, there seems to be an error with your session, could you please{" "}
          <Link to="/login">login</Link> again. If the problem does not go away
          please contact the IRIS admin team.
        </>
      );
    }

    // reset the trigger for next usage
    // setTimeout(() => {
    // setTriggerSaveMessage(false);
    // }, 300);
  };

  return (
    <Layout>
      {token && isAuthenticated ? (
        message ||
        (materialMetadataLoaded && filesLoaded ? (
          <div className="grid grid-cols-12 gap-4">
            {!isConfirmationStep ? (
              <div className="col-span-12 md:col-span-4">
                <LeftColumnContent
                  activeStep={step}
                  materialID={materialID}
                  projectName={projectName}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={`col-span-12 md:col-span-${
                isConfirmationStep ? 12 : 8
              }`}
            >
              <RightColumnContent
                step={stepInt}
                materialID={materialID}
                triggerSaveMessage={triggerSaveMessage}
                setSubmissionStatus={setSubmissionStatus}
                missingRequiredFields={missingRequiredFields}
                isSubmissionStep={isSubmissionStep}
                isConfirmationStep={isConfirmationStep}
              >
                <StepDisplay
                  step={stepInt}
                  submissionStatus={submissionStatus}
                  missingRequiredFields={missingRequiredFields}
                  triggerSaveMessageHandler={triggerSaveMessageHandler}
                  materialID={materialID}
                  materialSettings={materialSettings}
                />
              </RightColumnContent>
            </div>

            <div className={clsx("stepOverlay", stepOverlayClasses)}>
              <div className="innerContent">{stepOverlayMessage}</div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <LoadingIcon />
            <br />
            Loading the details, please wait.
          </div>
        ))
      ) : (
        <h2 className="text-2xl text-center pt-10 pb-10">
          Please <Link to="/login">login</Link> before submitting.
        </h2>
      )}
    </Layout>
  );
}

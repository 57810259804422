/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { graphql, useStaticQuery } from "gatsby";
import Pagination from "../contentElements/pagination";

import H1 from "../htmlElements/h1";
import LoadingIcon from "../loadingIcon";
import SearchResultIris from "./SearchResultIris";
import SearchResultOasis from "./SearchResultOasis";
import { getProjectName } from "../../lib/functions";

import { SearchStateContext } from "../../context/SearchContextProvider";
import { SearchResultsStateContext } from "../../context/SearchResultsContextProvider";
import CurrentFilters from "./CurrentFilters";

const sortingOptions = {
  relevance: "Relevance",
  // title: "Title",
  // author: "Author",
  publicationDate: "Publication date",
  publishedDate: "Published date",
};

function DisplaySortingOptions(props: any) {
  const { sortingOption, sortingOptionsChangeHandler } = props;

  const options = Object.entries(sortingOptions).map(([value, label]) => {
    return (
      <option value={value} key={uuidv4()}>
        {label}
      </option>
    );
  });

  return (
    <select
      value={sortingOption}
      onChange={(e) => sortingOptionsChangeHandler(e.target.value)}
    >
      {options}
    </select>
  );
}

function DisplayResultsPerPageOptions(props: any) {
  const { resultsPerPage, resultsPerPageChangeHandler } = props;

  const options = [2, 5, 10, 20, 50, 100].map((num) => {
    return (
      <option value={num} key={uuidv4()}>
        {num}
      </option>
    );
  });

  return (
    <select
      value={resultsPerPage}
      onChange={(e) => resultsPerPageChangeHandler(parseInt(e.target.value))}
    >
      {options}
    </select>
  );
}

export default function RightColumnContent(props: any) {
  const {
    sortingOption,
    sortingOptionsChangeHandler,
    resultsPerPage,
    resultsPerPageChangeHandler,
    currentPage,
  } = props;

  const searchState: any = useContext(SearchStateContext) || "";
  const searchResultsState: any = useContext(SearchResultsStateContext) || "";

  const { searchResults } = searchResultsState;

  const [searchResultsDisplay, setSearchResultsDisplay] = useState<string>("");
  const [headerText, setHeaderText] = useState("Search results for everything");

  const projectName = getProjectName(true);

  useEffect(() => {
    const hits = searchResults?.hits?.hits || [];

    const renderSearchResult = (result: object) => {
      const Component =
        projectName === "IRIS" ? SearchResultIris : SearchResultOasis;
      return <Component key={uuidv4()} result={result} />;
    };

    setSearchResultsDisplay(hits.map(renderSearchResult));
  }, [searchResults, projectName]);

  const numberOfResults =
    (searchResults && searchResults.hits.total.value) || 0;

  const totalPages = Math.ceil(numberOfResults / resultsPerPage);

  const resultsStart =
    numberOfResults === 0
      ? 0
      : resultsPerPage *
          (currentPage < 1
            ? 1
            : currentPage > totalPages
            ? totalPages
            : currentPage) -
        resultsPerPage +
        1;
  const resultsEnd =
    resultsStart + resultsPerPage - 1 > numberOfResults
      ? numberOfResults
      : resultsStart + resultsPerPage - 1;

  const selectedFacets: any = [];

  if (searchState && searchState.searchData && searchState.searchData.filters) {
    Object.keys(searchState.searchData.filters).forEach((name) => {
      if (name !== "containsFiles") {
        const values = searchState.searchData.filters[name];
        values.map((value: any) => {
          selectedFacets.push(value);
        });
      }
    });
  }

  const noResultsMessage = (
    <div className="text-sm mb-5">
      We could not found any results.
      {selectedFacets.length ? (
        <span>
          <br />
          <br />
          Try removing some selected filters to find more results.
          <br />
        </span>
      ) : (
        ""
      )}
      {/* <br /> */}
      {/* {selectedFacets} */}
    </div>
  );

  useEffect(() => {
    if (searchState) {
      if (
        searchState?.searchData?.keyword !== "*" &&
        searchState?.searchData?.keyword !== ""
      ) {
        let searchKeyword = decodeURI(searchState?.searchData?.keyword ?? "*");

        // Remove quotes at the start and end if they exist
        if (searchKeyword.startsWith('"') && searchKeyword.endsWith('"')) {
          searchKeyword = searchKeyword.slice(1, -1);
        }

        setHeaderText(`Search results for "${searchKeyword}"`);
      }
    }
  }, [searchState]);

  const { irisText, oasisText } = useStaticQuery(graphql`
    query {
      irisText: markdownRemark(frontmatter: { pageId: { eq: "IRIS-search" } }) {
        html
      }

      oasisText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-search" } }
      ) {
        html
      }
    }
  `);

  let searchText = "";

  if (projectName === "IRIS" && irisText) {
    searchText = irisText.html;
  }

  if (projectName === "OASIS" && oasisText) {
    searchText = oasisText.html;
  }

  return (
    <>
      <H1 innerContent={headerText} additionalClasses="pb-5" />

      {props.loading ? (
        <LoadingIcon textBeforeIcon="Loading results.." />
      ) : props.errorMessage ? (
        props.errorMessage
      ) : (
        <>
          <div
            className="markdownText mb-5"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: searchText,
            }}
          />

          <div className="inline-block w-full border-b border-b-gray-2 mb-0 pb-0">
            <div className="float-left text-sm mb-5 resultsFromTo">
              Results {resultsStart} - {resultsEnd} of {numberOfResults}
            </div>
            <div className="float-right text-sm ml-5">
              Sort by{" "}
              <DisplaySortingOptions
                sortingOption={sortingOption}
                sortingOptionsChangeHandler={sortingOptionsChangeHandler}
              />
            </div>
            <div className="float-right text-sm ml-5">
              Results per page{" "}
              <DisplayResultsPerPageOptions
                resultsPerPage={resultsPerPage}
                resultsPerPageChangeHandler={resultsPerPageChangeHandler}
              />
            </div>
          </div>

          <CurrentFilters />

          {searchResultsDisplay.length
            ? searchResultsDisplay
            : noResultsMessage}

          {numberOfResults > 0 ? (
            <Pagination
              params={props.params}
              query={
                searchState && searchState.searchData
                  ? searchState.searchData.keyword
                  : ""
              }
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPageHandler={props.setCurrentPageHandler}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

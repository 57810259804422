/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import { isEmpty } from "lodash";
import React, { useState, useContext, useEffect } from "react";
import Select, { components } from "react-select";
import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";
import InputWrapper from "../inputWrapper";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

interface InputFieldProps {
  triggerSaveMessageHandler?: any;
  materialSettings?: any;
  doiField?: boolean;
}

export default function TypeOfMaterialPrimarySelectField(
  props: InputFieldProps
) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { triggerSaveMessageHandler, materialSettings, doiField } = props;

  const [options, setOptions] = useState([]);
  const [fieldValues, setFieldValues] = useState([]);

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
    fieldName: "primaryMaterialType",
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: doiField || false,
  });

  // Update the field values when the submission state has changed (i.e. when the user has selected some material types)
  useEffect(() => {
    // Filter out `undefined` items which will turn into an error.
    const m = submissionState.instrument.materialType
      ? submissionState.instrument.materialType.filter(
          (item: any) => item !== undefined
        )
      : [];

    // This will set which options are displayed - based on what in Type of Material is selected
    setOptions(m);

    // This will set which option(s) are selected
    setFieldValues(submissionState.instrument.primaryMaterialType);
  }, [submissionState]);

  function Option(props: any) {
    /**
     * Create the indent we need for options
     */
    const indent = ""; // "\xa0".repeat(props.data.depth * 4); // <<< this is the indent (how many &nbsp; )

    return (
      <div>
        <components.Option {...props}>
          <div>
            {indent}
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              style={{ borderLeft: `1px solid #000` }}
            />{" "}
            <label>{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  }

  function SingleValue(props: any) {
    return <components.SingleValue {...props} />;
  }

  const customStyles = {
    container: (provided: any) => ({ ...provided, zIndex: 5 }),
    control: (provided: any) => ({ ...provided, display: "none" }),
    menu: (provided: any) => ({ ...provided, position: "relative", zIndex: 5 }),
  };

  return (
    <InputWrapper
      title="Choose which label best describes your deposit:"
      required
    >
      {noteText}
      <Select
        isDisabled={disabled}
        menuIsOpen
        isMulti={false}
        isSearchable={false}
        name="primary"
        placeholder="Please select..."
        options={options}
        styles={customStyles}
        hideSelectedOptions={false}
        onChange={(selectedOption) => {
          /**
           * We are only saving one selected option, so anytime a option is selected the previous ons is overwritten (unselected)
           */
          submissionDispatch({
            type: "instrumentChangeHandler",
            value: selectedOption || [],
            fieldName: "primaryMaterialType",
          });

          // Update current selected option
          setFieldValues(selectedOption || []);

          // Trigger the save handler
          setTimeout(() => {
            triggerSaveMessageHandler();
          }, 500);
        }}
        components={{
          Option,
          SingleValue,
        }}
        value={fieldValues}
      />
    </InputWrapper>
  );
}

TypeOfMaterialPrimarySelectField.defaultProps = {
  triggerSaveMessageHandler: () => {},
  materialSettings: {},
  doiField: false,
};

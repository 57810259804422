/* eslint-disable no-underscore-dangle */
import React from "react";

import Link from "../htmlElements/Link";
import { getAPAInlineRefAll } from "../../helpers/createAPAReference";

interface SearchResultProps {
  result: {
    _source: any;
    title: string;
  };
}

export default function SearchResultOasis(props: SearchResultProps) {
  const { result } = props;

  let summaryTitle = "";
  let publicationAPAInlineReference: any = "";

  let publicationGeneralResearchArea = "";
  let publicationParticipantType = "";
  let publicationDate = "";
  let participantAgeOfLearners = "";
  let publicationIrisUrls = "";
  let summaryOriginalArticleOpenAccess = false;

  // console.log("🚀 ~ result._source:", result._source);
  // console.log("🚀 ~ publicationAuthor:", result._source.publicationIrisUrls);

  // console.log("_id:", result?._id);
  // console.log("publicationDate:", result?._source?.publicationDate);

  try {
    summaryTitle = result?._source?.summaryTitle[0] || "";

    // if (result._id === "6395w817b") {
    //   console.log(
    //     "🚀 ~ summaryOriginalArticleOpenAccess:",
    //     result?._source.summaryOriginalArticleOpenAccess[0]
    //   );
    // }

    publicationGeneralResearchArea =
      result?._source?.publicationGeneralResearchArea
        .sort()
        .filter((item: any) => item !== null)
        .map((item: any) => item.replace("$#$", " > "))
        .join("; ") || "unknown";

    publicationParticipantType =
      result?._source?.publicationParticipantType
        .sort()
        .filter((item: any) => item !== null)
        .map((item: any) => item.replace("$#$", " > "))
        .join("; ") || "unknown";

    publicationAPAInlineReference =
      getAPAInlineRefAll(result._source.publicationAuthor) || "unknown";

    publicationDate = result?._source?.publicationDate;

    if (
      result?._source?.participantAgeOfLearners &&
      result?._source?.participantAgeOfLearners.length > 0
    ) {
      participantAgeOfLearners = result?._source?.participantAgeOfLearners[0];
    }

    if (
      result?._source?.publicationIrisUrls &&
      result?._source?.publicationIrisUrls.length > 0
    ) {
      publicationIrisUrls = result?._source?.publicationIrisUrls[0];
    }

    if (
      result?._source?.summaryOriginalArticleOpenAccess &&
      result?._source?.summaryOriginalArticleOpenAccess.length > 0
    ) {
      if (result?._source?.summaryOriginalArticleOpenAccess[0] === "Yes") {
        summaryOriginalArticleOpenAccess = true;
      }
    }
  } catch (error) {
    console.log("error:", error);
  }

  const detailsLink = `/details/${result._id}`;

  return (
    <div
      style={{ width: `100%` }}
      className="pb-5 mb-5 border-b border-b-gray-2 search-result-item"
    >
      <div className="grid grid-cols-12 gap-4">
        {summaryTitle ? (
          <div className="col-span-12">
            <h3>
              <Link
                to={detailsLink}
                text={summaryTitle}
                bold
                additionalClasses="no-underline hover:underline"
              />
            </h3>
          </div>
        ) : null}

        {publicationAPAInlineReference ? (
          <>
            <div className="col-span-3 text-sm">
              <strong>Publication</strong>:
            </div>
            <div className="col-span-9 text-sm">
              {publicationAPAInlineReference} ({publicationDate})
              {summaryOriginalArticleOpenAccess ? (
                <img
                  className="h-6 ml-2 inline"
                  src="/images/openaccess.png"
                  alt="Open Access"
                />
              ) : null}
            </div>
          </>
        ) : null}

        {publicationGeneralResearchArea ? (
          <>
            <div className="col-span-3 text-sm">
              <strong>Area of research</strong>:
            </div>
            <div className="col-span-9 text-sm">
              {publicationGeneralResearchArea}
            </div>
          </>
        ) : null}

        {publicationParticipantType ? (
          <>
            <div className="col-span-3 text-sm">
              <strong>Participant type</strong>:
            </div>
            <div className="col-span-9 text-sm">
              {publicationParticipantType}
            </div>
          </>
        ) : null}

        {participantAgeOfLearners ? (
          <>
            <div className="col-span-3 text-sm">
              <strong>Age</strong>:
            </div>
            <div className="col-span-9 text-sm">{participantAgeOfLearners}</div>
          </>
        ) : null}

        {publicationIrisUrls ? (
          <>
            <div className="col-span-3 text-sm">
              <img
                className="h-8 inline"
                src="/images/iris_logo_small.jpg"
                alt="OASIS Logo"
              />
            </div>
            <div className="col-span-9 text-sm">
              <a href={publicationIrisUrls} target="_blank" rel="noreferrer">
                See material on IRIS
              </a>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

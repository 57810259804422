/* eslint-disable no-restricted-syntax */
import OasisMapping from "../../oasisMapping.json";
import submissionSteps from "../settings/submissionSteps";
import { getProjectName } from "./functions";

const projectName = getProjectName();

export default {
  isSubmissionStep(currentStepNumber: number | string): boolean {
    const step = submissionSteps[projectName]?.find(
      (item) => item.step === Number(currentStepNumber)
    );
    return step?.submissionStep ?? false;
  },

  isConfirmationStep(currentStepNumber: number | string): boolean {
    const step = submissionSteps[projectName]?.find(
      (item) => item.step === Number(currentStepNumber)
    );
    return step?.confirmationStep ?? false;
  },

  isUpdateDoiReferenceStep(currentStepNumber: number | string): boolean {
    const step = submissionSteps[projectName]?.find(
      (item) => item.step === Number(currentStepNumber)
    );
    return step?.updateDoiReference ?? false;
  },

  getRequiredFieldsOasis(): object {
    return OasisMapping.filter((item: any) => item.frontend?.required);
  },

  /**
   * `checkRequiredFields` is currently only use for `oasis`
   */
  checkRequiredFields(submissionState: any): object {
    let requiredFields: any;
    const invalidFields: any = [];

    if (projectName === "oasis") {
      requiredFields = this.getRequiredFieldsOasis();
    }

    for (const requiredField of requiredFields) {
      let value: any = "";
      let valid = false;
      const { fieldName, partOf } = requiredField;

      if (submissionState) {
        if (partOf === "publication") {
          if (fieldName === "publicationDate") {
            value = [submissionState[partOf][0][fieldName].value];
          } else {
            value = submissionState[partOf][0][fieldName];
          }
        } else {
          value = submissionState[partOf][fieldName];
        }

        if (value) {
          if (value.length > 0) {
            valid = true;
          }
        }

        if (fieldName === "summaryOriginalArticleOpenAccess") {
          if (submissionState.summary.summaryOriginalArticleOpenAccess) {
            valid = true;
          }
        }

        if (fieldName === "settingsLicenceAgreement") {
          if (
            submissionState.settings.settingsLicenceAgreement.value === "true"
          ) {
            valid = true;
          }
        }

        if (fieldName === "settingsTermsAndConditions") {
          if (
            submissionState.settings.settingsTermsAndConditions.value === "true"
          ) {
            valid = true;
          }
        }
      }

      if (!valid) {
        invalidFields.push(requiredField);
      }
    }

    return invalidFields;
  },
};

/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import { isNumber } from "lodash";
import React, { useState, useContext, useEffect } from "react";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { getProjectName } from "../../../lib/functions";

import InputWrapper from "../inputWrapper";

interface HierarchicalSelectFieldProps {
  title?: string;
  isRequired?: boolean;
  options: any;
  inputName: string;
  irisMaterialSection?: string;
  infoBoxContent?: any;
  referenceNumber?: string | number;
  acknowledgementNumber?: string;
  triggerSaveMessageHandler?: any;
  materialSettings?: any;
  doiField?: boolean;
  inlineNoteText?: string;
  hideWrapper?: boolean;
}

export default function HierarchicalSelectField(
  props: HierarchicalSelectFieldProps
) {
  const submissionState: any = useContext(SubmissionState) || "";
  const submissionDispatch: any = useContext(SubmissionDispatch) || "";

  const projectName = getProjectName(true);

  const {
    title,
    isRequired,
    options,
    inputName,
    irisMaterialSection,
    infoBoxContent,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
    materialSettings,
    doiField,
    inlineNoteText,
    hideWrapper,
  } = props;

  const [fieldValues, setFieldValues] = useState<any>([]);

  // const fieldValues = InputFieldsHelper.getFieldValues({
  //   referenceNumber,
  //   acknowledgementNumber,
  //   name,
  //   submissionState,
  //   projectName,
  // });

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name: inputName,
    projectName,
  });

  let disabled = false;

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
    fieldName: inputName,
  });

  const noteText =
    inlineNoteText ||
    InputFieldsHelper.getInputDisabledNote({
      materialSettings,
      doiField: doiField || false,
    }) ||
    null;

  /**
   * Get the selected values for this field
   */
  useEffect(() => {
    let selectedValues = [];

    try {
      if (inputName) {
        if (irisMaterialSection && submissionState[irisMaterialSection]) {
          if (irisMaterialSection === "publication") {
            selectedValues = submissionState[irisMaterialSection][0][inputName];
          } else {
            selectedValues = submissionState[irisMaterialSection][inputName];
          }
        } else if (submissionState[inputName]) {
          selectedValues = submissionState[inputName];
        } else {
          // do nothing
        }
      }
    } catch (error) {
      console.log("hierarchicalSelectField selectedValues error: ", error);
    }

    if (selectedValues) {
      setFieldValues(selectedValues);
    }
  }, []);

  // Create array with all parent for later use
  const allParents = options.filter(
    (option: any) => option.subItems.length > 0
  );

  /**
   * This function will add or remove items :) We can only add children NOT parents!
   * What that means that you can add `Code for R` and `Other Code` (which are both children) but NOT `Code` (which is a parent).
   *
   * CHECK 1
   * After adding/removing options we check if we need to remove a `parent` from the list if no `children` have been selected
   *
   * CHECK 2
   * After CHECK 1 we we check if we need to add a `parent` when all `children` have been added.
   *
   */
  const selectChildren = (thisOption: any) => {
    let selectedOptions = [...fieldValues];

    // Get children from selected option
    const children = options.filter((item: any) =>
      thisOption.data.subItems.includes(item.id)
    );

    if (thisOption.isSelected) {
      // remove option
      selectedOptions = selectedOptions.filter(
        (item: any) => item.value !== thisOption.data.value
      );

      // remove children
      children.map((option: any) => {
        selectedOptions = selectedOptions.filter(
          (item) => item.value !== option.value
        );
      });
    } else {
      // add option
      selectedOptions.push(thisOption.data);

      // add children
      children.map((option) => {
        if (!selectedOptions.includes(option)) {
          selectedOptions.push(option);
        }
      });
    }

    /**
     * CHECK 1
     * ----------
     * Check if selected options have children that are selected, if not, remove the main item (parent)
     * As in: remove `Code` when `Code for R` or `Other Code` are not anymore selected!
     */
    selectedOptions.map((selectedOption: any) => {
      let isAllowed = false;

      if (selectedOption !== null) {
        if (selectedOption.subItems && selectedOption.subItems.length > 0) {
          selectedOption.subItems.map((subItemId: any) => {
            if (
              selectedOptions.map((item: any) => item.id).includes(subItemId)
            ) {
              isAllowed = true;
            }

            return "";
          });
        } else {
          isAllowed = true;
        }
      }

      if (!isAllowed) {
        // remove
        selectedOptions = selectedOptions.filter(
          (item) => item !== selectedOption
        );
      }

      return "";
    });

    /**
     * CHECK 2
     * ----------
     * Add main item (parent) when ALL children have been added
     * As in: add `Code` when both `Code for R` or `Other Code` have been added.
     */
    const selectedIds = selectedOptions.map((item) => item.id);

    allParents.map((parent: any) => {
      parent.subItems.map((subItemId: string) => {
        if (selectedIds.includes(subItemId)) {
          // Make sure the parent in not alreadt in `selectedOptions`
          if (!selectedOptions.map((item) => item.id).includes(parent.id)) {
            selectedOptions.push(parent);
          }
        }
      });
    });

    /**
     * Sort the selectedOptions
     */
    selectedOptions.sort(function (a, b) {
      return a.order - b.order;
    });

    if (inputName === "materialType") {
      // Perform special dispatch

      let primaryMaterialType = submissionState.instrument?.primaryMaterialType;
      const primaryMaterialTypeId =
        primaryMaterialType[0]?.id || // or use new value as set by useEfect hook. Note object is not in the array.
        primaryMaterialType?.id;
      // Deselect primaryMaterialType if it was reomeved from MaterialType list
      if (
        !selectedOptions
          .map((item: any) => item.id)
          .includes(primaryMaterialTypeId)
      ) {
        primaryMaterialType = []; // clear primaryMaterialType
      }

      // Now submit both values to the special dispatch function
      submissionDispatch({
        type: "materialTypeAndPrimaryTypeDispatch",
        value: {
          materialType: selectedOptions,
          primaryMaterialType,
        },
      });

      // End of special dispatch :)
    } else {
      // Perform normal dispatch
      submissionDispatch({
        type: onChangeHandler,
        value: selectedOptions,
        fieldName: inputName,
        referenceNumber,
        acknowledgementNumber,
      });
    }

    setFieldValues(selectedOptions);

    setTimeout(() => {
      triggerSaveMessageHandler();
    }, 500);
  };

  function Option(props: any) {
    const { selectProps, isSelected, label, data } = props;

    /**
     * We only perform the click action from the `main` dropdown menu, not the dropdown menu for selecting
     * the primary material type.
     */
    const onClickHandler = () => {
      if (selectProps?.selectProps?.isPrimaryDropdown) {
        // do not preform a click action
        // (a click action would add the option again to the choosen options)
      } else {
        selectChildren(props);
      }
    };

    /**
     * Create the indent we need for options
     */
    const indent = "\xa0".repeat(data.depth * 4); // <<< this is the indent (how many &nbsp; )

    /**
     * Remove indent when its for the primary material type dropdown menu AND the parent item is not choosen.
     */
    // if (props.selectProps?.selectProps?.isPrimaryDropdown) {
    //   const isParentHere = fieldValues.filter(
    //     (item) => item.id === props.data.parent
    //   );
    //   if (isParentHere.length === 0) {
    //     indent = "";
    //   }
    // }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.Option {...props}>
        <div onClick={onClickHandler} aria-hidden="true" key={uuidv4()}>
          {indent}
          <input
            key={uuidv4()}
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            style={{ borderLeft: `1px solid #000` }}
          />{" "}
          {label}
        </div>
      </components.Option>
    );
  }

  function ValueContainer(props: any) {
    const { children } = props;
    const { length } = children[0];
    const tmpChildren = [...children];
    if (length >= 2) {
      tmpChildren[0] = `${length} languages`;
    }
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.ValueContainer {...props}>
        {tmpChildren}
      </components.ValueContainer>
    );
  }

  function MultiValueRemove(props: any) {
    const optionProps = { ...props, isSelected: true };
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.MultiValueRemove {...props}>
        <div onClick={() => selectChildren(optionProps)} aria-hidden="true">
          x
        </div>
      </components.MultiValueRemove>
    );
  }

  const customStyles = {
    container: (provided: any) => ({ ...provided, zIndex: 5 }),
    control: (provided: any) => ({ ...provided, display: "none" }),
    menu: (provided: any) => ({ ...provided, position: "relative", zIndex: 5 }),
  };

  // const availableOptions = options.filter(
  //   (item: any) =>
  //     item.value !== "" &&
  //     item.label !== "" &&
  //     item.status !== "REJECTED" &&
  //     item.status !== "NEW"
  // );

  /**
   * Filter the `options` to remove `REJECTED` options unless it's specific for this material.
   */
  const filteredOptions = options
    .filter(
      (item: any) =>
        item.status !== "REJECTED" ||
        item.materialId === submissionState.objectId
    )
    .filter((item: any) => item.label.toLowerCase() !== "other");

  const select = (
    <Select
      key={inputName}
      name={inputName}
      isDisabled={disabled}
      isMulti
      menuIsOpen
      hideSelectedOptions={false}
      menuShouldScrollIntoView={false}
      isSearchable
      placeholder="Please select..."
      options={filteredOptions}
      styles={customStyles}
      // Use custom Option ,MultiValueRemove and ValueContainer
      components={{
        Option,
        MultiValueRemove,
        ValueContainer,
      }}
      value={fieldValues}
      onChange={(selectedOption) => {
        //
      }}
    />
  );

  return hideWrapper ? (
    <div style={{ minHeight: `200px` }}>
      {noteText}
      {select}
    </div>
  ) : (
    <InputWrapper
      title={title}
      infoBoxContent={infoBoxContent}
      required={isRequired}
    >
      {noteText}
      {select}
    </InputWrapper>
  );
}

// eslint-disable-next-line import/prefer-default-export

import OasisMapping from "../../oasisMapping.json";
import { getProjectName } from "../lib/functions";

interface SchemaItem {
  openSearchName: string;
  displayName: string;
  facetSize: number;
}

const DEFAULT_FACET_SIZE = 10000;

const irisSchema = [
  {
    openSearchName: "s_materialType",
    displayName: "Type of Material",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_creators",
    displayName: "Author of Publication",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_researchArea",
    displayName: "General Research Area",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_linguisticFeature",
    displayName: "Linguistic Feature",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_languageBeingLearned",
    displayName: "Language being used/learned",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_firstLanguage",
    displayName: "First language of learners",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_proficiencyOfLearners",
    displayName: "Proficiency of learners",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_participantType",
    displayName: "Participant Type",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_publicationDate",
    displayName: "Date of Publication/Award",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_publicationType",
    displayName: "Type of Publication(s)",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_journalName",
    displayName: "Journal title(s)",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_bookTitle",
    displayName: "Book Title",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_language",
    displayName: "Language in which any instructions are written",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_dataType",
    displayName: "Data Type",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_proficiencyStudentsTaught",
    displayName: "Proficiency of Student Taught",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_genderOfLearners",
    displayName: "Gender of users/learners",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_yearsOfExperience",
    displayName: "Years of Teaching Experience",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_requiredSoftware",
    displayName: "Proprietary Software Name",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_domainOfUse",
    displayName: "Domains of use of language use/learning",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_sourceOfFunding",
    displayName: "Funding",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_publisher",
    displayName: "Publisher",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_placeOfPublication",
    displayName: "Place of Publication",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_publicationEditor",
    displayName: "Editor",
    facetSize: DEFAULT_FACET_SIZE,
  },
  {
    openSearchName: "s_conferenceName",
    displayName: "Conference Name",
    facetSize: DEFAULT_FACET_SIZE,
  },
  // {
  //   openSearchName: "s_numberOfFiles",
  //   displayName: "Number Of Files",
  //   facetSize: DEFAULT_FACET_SIZE,
  // },
];

const oasisSchemaExclude = ["numberOfFiles"];

const oasisSchema = OasisMapping.filter(
  // (item: any) => item.isKeywordSearch && item.ddCategoryKey
  (item: any) => item.isKeywordSearch && !oasisSchemaExclude.includes(item.fieldName)
).map((item: any) => {
  return {
    openSearchName: `s_${item.fieldName}`,
    displayName: item.displayName,
    facetSize: DEFAULT_FACET_SIZE,
  };
});

const createRenamedFacetsArray = (inputSchema: any) => {
  const renamedFacetsTitles: { title: string; newTitle: string }[] = [];

  // eslint-disable-next-line array-callback-return
  inputSchema.map((item: SchemaItem) => {
    renamedFacetsTitles.push({
      title: item.openSearchName,
      newTitle: item.displayName,
    });
  });

  return renamedFacetsTitles;
};

const getRenamedFacetsTitles = () => {
  const projectName = getProjectName(true);

  return createRenamedFacetsArray(
    projectName === "OASIS" ? oasisSchema : irisSchema
  );
};

export default getRenamedFacetsTitles;

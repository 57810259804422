import React from "react";
import { v4 as uuidv4 } from "uuid";

interface OasisVideoProps {
  filesMetadata: any;
}

const OasisVideo = (props: OasisVideoProps) => {
  const { filesMetadata } = props;

  const handlePlay = (url: string) => {
    if (typeof window !== "undefined") {
      if (window && window.gtag) {
        gtag("event", "video_play", {
          event_category: "Video",
          event_label: "User played video",
          video_url: url,
        });
      }
    }
  };

  const videos = filesMetadata.map((item: any) => {
    if (item.extension === "mp4") {
      const url = `https://staging.oasis-database.org/${item.url}`;
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          preload="auto"
          id="video_download"
          key={uuidv4()}
          onPlay={() => handlePlay(url)}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return null;
  });

  return videos;
};

export default OasisVideo;

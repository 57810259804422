import { isArray, isEmpty } from "lodash";
import OasisMapping from "../../../oasisMapping.json";

interface OasisMetadataReverseFormatterProps {
  dictionaryData: any;
  metadata: any;
}

function lookupDictionaryData(
  dictionaryData: Record<string, any[]>,
  ddCategoryKey: string,
  selectedValues: string[]
) {
  const returnArray: any = [];

  selectedValues.map((id: any) => {
    const ddItem = dictionaryData[ddCategoryKey]?.filter(
      (item) => item.id === id
    );

    returnArray.push(ddItem[0]);
  });

  return returnArray;

  // return (
  //   dictionaryData[ddCategoryKey]?.filter((item) =>
  //     selectedValues.includes(item.id)
  //   ) || []
  // );
}

function getSelectedValues(fieldData: any[]): string[] {
  return Array.isArray(fieldData)
    ? fieldData
        .filter((item) => !isEmpty(item))
        .map((item) => item.value)
        .filter(Boolean)
    : [];
}

function processFieldData(
  partOf: string,
  fieldName: string,
  dictionaryData: Record<string, any[]>,
  ddCategoryKey: string,
  convertedMetadata: Record<string, any>
) {
  const fieldData =
    partOf === "publication"
      ? convertedMetadata[partOf]?.[0]?.[fieldName]
      : convertedMetadata[partOf]?.[fieldName];

  if (Array.isArray(fieldData)) {
    const selectedValues = getSelectedValues(fieldData);
    const dictionaryValues = lookupDictionaryData(
      dictionaryData,
      ddCategoryKey,
      selectedValues
    );

    if (partOf === "publication") {
      convertedMetadata[partOf][0][fieldName] = dictionaryValues;
    } else {
      convertedMetadata[partOf][fieldName] = dictionaryValues;
    }
  }
}

const oasisMetadataReverseFormatter = ({
  dictionaryData,
  metadata,
}: OasisMetadataReverseFormatterProps) => {
  const convertedMetadata = metadata.data || [];

  if (metadata.data) {
    try {
      const ddFields = OasisMapping.filter(
        (item) => item.isKeywordSearch && item.ddCategoryKey
      );

      ddFields.forEach(({ partOf, fieldName, ddCategoryKey }) => {
        if (partOf && fieldName && ddCategoryKey) {
          processFieldData(
            partOf,
            fieldName,
            dictionaryData,
            ddCategoryKey,
            convertedMetadata
          );
        }
      });
    } catch (error) {
      console.error("Error in oasisMetadataReverseFormatter:", error);
    }

    try {
      convertedMetadata.publication[0].publicationType = ["Journal Article"];
    } catch (error) {
      console.log("🚀 ~ publicationType:", error);
    }

    try {
      if (convertedMetadata.summary.summaryOriginalArticleOpenAccess) {
        if (
          convertedMetadata.summary.summaryOriginalArticleOpenAccess === "Yes"
        ) {
          convertedMetadata.summary.summaryOriginalArticleOpenAccess = {
            label: "Yes",
            value: "true",
          };
        } else {
          convertedMetadata.summary.summaryOriginalArticleOpenAccess = {
            label: "No",
            value: "false",
          };
        }
      }
    } catch (error) {
      console.log("🚀 ~ summaryOriginalArticleOpenAccess:", error);
    }

    if (convertedMetadata.settings) {
      try {
        if (convertedMetadata.settings.settingsLicenceAgreement === "Yes") {
          convertedMetadata.settings.settingsLicenceAgreement = {
            label:
              "I accept that the OASIS summary will be published under a CC BY-NC-SA license",
            value: "true",
          };
        }

        if (convertedMetadata.settings.settingsLicenceAgreement === "No") {
          convertedMetadata.settings.settingsLicenceAgreement = {
            label:
              "I don't accept that the OASIS summary will be published under a CC BY-NC-SA license",
            value: "false",
          };
        }
      } catch (error) {
        console.log("🚀 ~ settingsLicenceAgreement:", error);
      }

      try {
        if (convertedMetadata.settings.settingsTermsAndConditions === "Yes") {
          convertedMetadata.settings.settingsTermsAndConditions = {
            label: "Yes, I agree to the terms and conditions.",
            value: "true",
          };
        }

        if (convertedMetadata.settings.settingsTermsAndConditions === "No") {
          convertedMetadata.settings.settingsTermsAndConditions = {
            label: "No, I don't agree to the terms and conditions.",
            value: "false",
          };
        }
      } catch (error) {
        console.log("🚀 ~ settingsTermsAndConditions:", error);
      }
    }
  }
  // console.debug("Converted Metadata:", JSON.stringify(convertedMetadata));

  return convertedMetadata;
};

export default oasisMetadataReverseFormatter;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../htmlElements/button";
import H2 from "../htmlElements/h2";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../context/SubmissionContext";
import { submitMaterialMetadata } from "./submitMaterialMetadata";
import { getProjectName } from "../../lib/functions";

const UploadedFilesList = (props: any) => {
  const submissionState = useContext(SubmissionState) || { objects: {} };
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { objects } = submissionState;

  const { uppy, token, materialSettings } = props;

  const removeFileHandler = async (uploadedObject: any) => {
    // 1 - remove file from UPPY array
    uppy.removeFile(uploadedObject.id);

    // 2 - remove file from submissionState.objects
    submissionDispatch({
      type: "removeObject",
      value: uploadedObject.id,
    });

    // Submit material
    await submitMaterialMetadata({
      submissionState,
      token,
    });

    // 3 - remove file from S3 - upload bucket
    // For new materials we could ignore this as the uploaded file won't be moved to the production bucket.

    // 4 - remove file from S3 - production bucket
    // ...

    // 5 - if existing material remove file from DynamoDB `Files#All` record
    // ...
  };

  const isArray = Array.isArray(objects);
  return isArray
    ? objects.map((uploadedObject: any) => {
        return uploadedObject !== null ? (
          <tr key={uuidv4()}>
            <td className="border border-slate-300 border-gray-2 text-left p-1">
              {uploadedObject.originalFileName}
            </td>
            <td className="border border-slate-300 border-gray-2 text-right p-1 whitespace-nowrap">
              {Math.round(uploadedObject.size / 1024)} kb
            </td>
            {materialSettings.canRemoveFiles ? (
              <td className="border border-slate-300 border-gray-2 text-right p-1 whitespace-nowrap">
                <Button
                  color="iris-blue-light"
                  innerContent="Remove file"
                  paddingClasses="p-1"
                  textSize="small"
                  additionalClasses="text-white px-3"
                  onClick={() => removeFileHandler(uploadedObject)}
                />
              </td>
            ) : (
              ""
            )}
          </tr>
        ) : (
          ""
        );
      })
    : "";
};

function UploadedFilesTable(props: any) {
  const { uppy, token, materialSettings } = props;

  const projectName = getProjectName(true);

  const title = projectName === "OASIS" ? "Uploaded summary" : "Uploaded files";

  return (
    <>
      <H2 innerContent={title} paddingClasses="pb-2" />
      <table className="w-full border-collapse border border-slate-300 border-gray-2 mb-5">
        <thead>
          <tr>
            <th className="border border-slate-300 border-gray-2 text-left p-1">
              File name
            </th>
            <th className="border border-slate-300 border-gray-2 text-right p-1 whitespace-nowrap">
              File size
            </th>
            {materialSettings.canRemoveFiles ? (
              <th className="border border-slate-300 border-gray-2 text-right p-1">
                Actions
              </th>
            ) : (
              ""
            )}
          </tr>
        </thead>
        <tbody>
          <UploadedFilesList
            uppy={uppy}
            token={token}
            materialSettings={materialSettings}
          />
        </tbody>
      </table>
    </>
  );
}

interface UploadedFilesProps {
  uppy: any;
  token: string;
  materialSettings: Object;
}

function UploadedFiles(props: UploadedFilesProps) {
  const { uppy, token, materialSettings } = props;

  return (
    <UploadedFilesTable
      uppy={uppy}
      token={token}
      materialSettings={materialSettings}
    />
  );
}

export default UploadedFiles;
